<template>
  <div class="common-layout">
    <el-header class="header"><!-- 头部区 -->
      <div class="header-left">
        <div class="header-left-left">
          <img src="../assets/logo.png" alt="" height="50px"/>
          <span>数据可视化中心</span>
        </div>
        <div class="header-left-right">
          <el-switch
              style="margin: 0 auto"
            v-if="uploadReady"
            active-text="折线图"
            v-model="showLine"
            active-color="#13ce66"
            inactive-color="#ff4949">
          </el-switch>
          <el-select v-if="uploadReady" style="margin: 0 auto" v-model="roleLine" placeholder="请选择">
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :change="handleChangeWithLine"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="header-right">
        <div class="header-right-left"></div>
          <div class="header-right-right">
            <el-switch
                style="margin: 0 auto"
                v-if="uploadReady"
                active-text="饼图"
                v-model="showPie"
                :change="handleChangeWithPie"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
            <el-select v-if="uploadReady" style="margin: 0 auto" v-model="rolePie" placeholder="请选择">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
          </el-select>
        </div>
      </div>
    </el-header>
    <el-main v-if="!uploadReady">
      <el-upload
          class="upload-demo"
          drag
          action="action"
          :http-request="handleFileToMakeChoice"
          :before-upload="beforeHandle"
          multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传csv文件，且不超过500MB</div>
      </el-upload>
    </el-main>
    <el-main v-else class="mainShow">
      <div v-if="showOne" class="show">
        <router-view v-if="showLine" :preData="preData" :role="roleLine" name="lineChart"></router-view>
        <router-view v-if="showPie" :preData="preData" :role="rolePie" name="pieChart"></router-view>
      </div>
      <div v-else class="show">
        <div class="showLeft">
          <router-view v-if="showLine" :preData="preData" :role="roleLine" name="lineChart"></router-view>
        </div>
        <div class="showRight">
          <router-view v-if="showPie" :preData="preData" :role="rolePie" name="pieChart" ></router-view>
        </div>
      </div>
    </el-main>

    <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
      >
        <span>请选择分类字段</span>
      <el-select style="margin: 0 auto" v-model="groupByFiled" placeholder="请选择分类字段">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
    </el-dialog>

    <a href="https://beian.miit.gov.cn" class="icp">渝ICP备2020012610号-1</a>
  </div>
</template>

<script>
import * as XLSX from "xlsx";

export default {
  name: "Main",
  data() {
    return {
      rawData:null,
      groupByFiled:"",
      dialogVisible:false,
      showOne:true,
      showLine:false,
      showPie:false,
      roleLine:"",
      rolePie:"",
      props: {
        expandTrigger: 'hover',
      },
      options: [],
      uploadReady:false,
      preData:[],
    }
  },
  watch:{
    groupByFiled:{
      handler(newValue){
        //选出分类的字段后要将其移除后续展示列段
        //分类字段在后期不可作为选择
        this.options = this.options.filter(item=>item.value!=newValue)
        if(newValue!=null&&newValue!=""){
          this.dialogVisible=false;
        }
        this.handleFile();
      }
    },
    showLine:{
      handler(){
        this.showOne=!(this.showLine&&this.showPie)
        this.$router.replace({
          name:"Show"
        }).catch(()=>{})
      }
    },
    showPie:{
      handler(){
        this.showOne=!(this.showLine&&this.showPie)
        console.log(this.showOne)
        this.$router.replace({
          name:"Show"
        }).catch(()=>{})
      }
    },
  },
  methods: {
    handleChangeWithLine() {
      this.$router.replace({
        name:"Show"
      }).catch(()=>{})
    },
    handleChangeWithPie() {
      this.$router.replace({
        name:"Show"
      }).catch(()=>{})
    },

    beforeHandle (file) { // 上传文件之前钩子
      const type = file.name.split('.')[1]
      if (type !== 'csv') {
        this.$message({ type: 'error', message: '只支持csv文件格式！' })
        return false
      }
    },

    //让用户做出分类选择，以便后面数据区分
    handleFileToMakeChoice(param){
      let file = param.file;
      //数据保存
      this.rawData = file;
      this.csvToData(file).then((data)=>{
        for(let key in data[0]){
          let data = {
            value: key,
            label: key
          }
          this.options.push(data);
        }
        this.dialogVisible=true;
      });
    },

    //正式的处理数据文件
    handleFile () {
      let file = this.rawData
      this.csvToData(file).then((data)=>{
        const groupBy = (array, key) => {
          const groups = {}
          array.forEach(function (o) {
            const group = o[key]
            groups[group] = groups[group] || []
            groups[group].push(o)
          })
          return Object.values(groups)
        }

        const mergeList = groupBy(data, this.groupByFiled)

        for (let i = 0; i < mergeList.length; i++) {
          let megerData = {
            name: mergeList[i][0][this.groupByFiled],
            data:mergeList[i]
          }
          this.preData.push(megerData)
          this.uploadReady=true;
        }
      })
    },
    csvToData(file){
      return new Promise((resolve)=>{
        let reader = new FileReader();
        reader.readAsArrayBuffer(file)
        reader.onload= e => {
          //获取数据
          let data = e.target.result;
          let workbook = XLSX.read(data, { type: 'array' })
          let firstSheetName = workbook.SheetNames[0]
          let worksheet = workbook.Sheets[firstSheetName]
          let results = XLSX.utils.sheet_to_json(worksheet, {range: 0})
          resolve(results);
        }
      })
    }
  },
  components: {}
}
</script>

<style scoped>
.header {
  background-color: dimgray;
  display: flex;
  justify-content: space-between;
  padding-left: 0;
  align-items: center;
  color: #fff;
  font-size: 20px;
}
.header-left{
  display: flex;
  width: 48%;
  margin: 0 auto;
  align-items:center;
}
.header-right{
  display: flex;
  width: 48%;
  align-items:center;
  margin: 0 auto;
}
.header-left-left{
  width: 45%;
  display: flex;
  align-items:center;
}
.header-left-right{
  width: 45%;
  margin-right: 0;
  align-items:center;
  display: flex;
}

.header-right-left{
    width: 23%;
}
.header-right-right{
  width: 45%;
  margin-right: 0;
  align-items:center;
  display: flex;
}
.icp{
  position: absolute;
  bottom: 2px;
  font-size: small;
}

.mainShow{
  height: 100vh;
}
.show{
  width: 100%;
  height: 100%;
  display: flex;
}
.showLeft{
  width: 48%;
  height: 100%;
  margin: 0 auto;
}
.showRight{
  width: 48%;
  margin: 0 auto;
  height: 100%;
}
</style>